// the ~ allows you to reference things in node_modules
@import 'settings.scss';
@import '~foundation-sites/scss/foundation.scss';



.sub-nav {
    margin-top: 0px;
    li {
        list-style-type: none;
        &.active a {
            background-color: $orange-color;
            &:hover {
                background-color: scale-color($orange-color, $lightness: $button-function-factor)
            }
        }
    }
}

form {
    .columns {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }
}

input, select, textarea {
    &.erreur {
        background-color: transparentize($alert-color, 0.5);
    }
}

.error  {
    select, input, textarea {
        background-color: transparentize($alert-color, 0.5);
    }
}

.panel {
    &.erreur {
        background-color: lighten($alert-color, 25%);
    }
}

// Checkbox et radio
// S'assure que le label est sur la même ligne que le bouton
.inline-choice {
    margin-left: 20px;
    input[type="radio"], input[type="checkbox"] {
      float: left;
      margin-left: -20px;
      margin-top: 4px;
    }
}

table {
    tr {
        &.groupe-pair {
            background-color: #eaf6f6;
        }

        &.groupe-impair {
            background-color: #b8e1e3;
        }
        td {
            &.indente {
                padding-left: 30px;
            }
            &.indente2 {
                padding-left: 60px;
            }
        }
    }
    width: 100%;
}

table.text-center {
    tr th, tr td {
        text-align:center;
    } 
} 

h1 { 
    font-size: rem-calc(30); 
    text-transform: uppercase;
    font-weight: bold;
}
h2 { 
    font-size: rem-calc(26); 
    text-transform: uppercase;
    font-weight: bold;
}
h3 { font-size: rem-calc(24); }
h4 { font-size: rem-calc(21); }
h5 { font-size: rem-calc(18); }
h6 { font-size: rem-calc(16); }

h1, h2, h3, h4, h5, h6 {
    color: $secondary-color;
}